











































































































































































































































.home-wrapper1 {
  // background-size: 100% 100%;
}
.home-wrapper {
  // width: 1920px;
  // height: 1080px;
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    // display: none;
  }
  .home-center-content {
    width: 100%;
    min-height: 100vh;
    // padding-top: 78px;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100%;
    overflow: auto;
    .page-wrapper {
      // min-height: calc(100vh - 78px - 100px);
      // width: 1200px;
      width: 1100px;
      margin: 0 auto;
      // padding-bottom: 140px;
    }
    .resource-content {
      padding: 0;
    }
  }
  .reversion-box {
    position: fixed;
    bottom: 0;
    left: 0;
    color: #000;
    margin-left: 20px;
    z-index: 999;
    white-space: nowrap;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
  }
}
::-webkit-scrollbar {
  display: none;
}

.bottom_Bg {
  width: 99.15%;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}
//
