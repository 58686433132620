.home-wrapper {
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: auto;
}
.home-wrapper .home-center-content {
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
  overflow: auto;
}
.home-wrapper .home-center-content .page-wrapper {
  width: 1100px;
  margin: 0 auto;
}
.home-wrapper .home-center-content .resource-content {
  padding: 0;
}
.home-wrapper .reversion-box {
  position: fixed;
  bottom: 0;
  left: 0;
  color: #000;
  margin-left: 20px;
  z-index: 999;
  white-space: nowrap;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
}
::-webkit-scrollbar {
  display: none;
}
.bottom_Bg {
  width: 99.15%;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}
