






































































































































































































































































































































































































































































































.top-bar-wrapper {
  width: 100%;
  height: 0vh;
  padding: 0 0.4rem;
  // background: #fffaf1;
  position: relative;
  top: 0;
  left: 0;
  z-index: 100;
  text-align: center;
  // box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);

  .top-bar-content {
    height: 88px;
    display: flex;
    justify-content: center;
    position: relative;
    &.h-88 {
      height: 88px;
    }
    .top-bar-logo {
      // margin-top: 24px;
      position: absolute;
      left: 0;
      .img-home {
        // width: 84px;
        // height: 50px;
        width: 2.05rem;
        height: 0.75rem;
        margin-left: 0.1rem;
        margin-top: 0.34rem;
      }
      .img-to-home {
        // height: 40px;
        margin-top: 0.2rem;
        // width: 70px;
        // height: 70px;

        width: 0.7rem;
        height: 0.7rem;
        // animation: logo_fadeIn 0.5s;
        background: url("../../assets/new_ui/logo_home.png") no-repeat;
        background-size: 100% 100%;
        &:hover {
          // filter: saturate(125%) brightness(108%);
          background: url("../../assets/new_ui/logo_home_hover.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .menu-center {
      display: inline-block;
      position: absolute;
      left: 50%;
      margin-left: -80px;
      background-position: center bottom;
      transform-style: preserve-3d;

      &.menu-center-fold {
        width: 180px;
        height: 110px;
        bottom: -28px;

        animation: flipClose 0.4s linear forwards;
        .img-menu {
          cursor: pointer;
          position: absolute;
          text-align: center;
          left: 50%;
          margin-left: -90px;
          bottom: 4px;
          // transition: opacity .4s ease-in;
          // backface-visibility: hidden;
        }
      }
      &.menu-center-open {
        width: 180px;
        height: 70px;
        overflow: hidden;
        bottom: -54px;

        animation: flipOpen 0.4s linear forwards;
        .img-menu {
          cursor: pointer;
          position: absolute;
          text-align: center;
          left: 50%;
          margin-left: -85px;
          bottom: 8px;

          // backface-visibility: visible;
          // transition: opacity .3s ease-in;
        }
      }
    }
    .top-bar-avatar {
      margin-top: 0.2rem;
      position: absolute;
      right: 0;
      width: 0.7rem;
      height: 0.7rem;
      .img-avatar {
        // width: 50px;
        // height: 50px;
        width: 0.7rem;
        height: 0.7rem;
        background: url("../../assets/new_ui/avatar_header.png") no-repeat;
        background-size: 100% 100%;

        &:hover {
          // filter: saturate(125%) brightness(108%);
          background: url("../../assets/new_ui/avatar_header_hover.png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
      .img-avatar-login {
        width: 0.7rem;
        height: 0.7rem;
        background: url("../../assets/new_ui/avatar_header_gray.png") no-repeat;
        background-size: 100% 100%;

        &:hover {
          filter: saturate(125%) brightness(108%);
          // background: url("../../assets/new_ui/avatar_header_hover.png")
          //   no-repeat;
          // background-size: 100% 100%;
        }
      }
    }
    .top-menu-box {
      display: inline-block;
      // margin-top: 26.5px;
      margin-top: 10px;
      margin-right: 5px;
      position: relative;
      // left: -100px;
      .menu-item {
        display: inline-block;
        opacity: 0;
        &:nth-of-type(1) {
          animation: bounceIn 0.5s linear 0.4s forwards;
        }
        &:nth-of-type(2) {
          animation: bounceIn 0.5s linear 0.4s forwards;
        }
        &:nth-of-type(3) {
          animation: bounceIn 0.5s linear 0.4s forwards;
        }
        &:nth-of-type(4) {
          animation: bounceIn 0.5s linear 0.4s forwards;
        }
        &:nth-of-type(5) {
          animation: bounceIn 0.5s linear 0.4s forwards;
        }

        & + .menu-item {
          margin-left: 0.8rem;
        }
        img {
          // width: 159px;
          width: 1.6rem;
        }
        .img-menu:hover {
          // animation: rubberBand 0.4s linear;
          animation-name: tada;
          transform-origin: center bottom;
          animation-duration: 0.5s;
          animation-fill-mode: both;
        }
      }
    }
  }
  .left-back-box {
    margin-top: 0.17rem;
    width: 0.7rem;
    height: 0.7rem;
    position: absolute;
    left: 0.4rem;
    // bottom: 23px;
    top: 0.03rem;
    .img-go-back {
      width: 100%;
      height: 100%;
      // animation: logo_fadeIn 0.5s;
      background: url("../../assets/new_ui/icon-arrow-left.png") no-repeat;
      background-size: 100% 100%;
      &:hover {
        // filter: saturate(125%) brightness(108%);
        background: url("../../assets/new_ui/icon-arrow-left-hover.png")
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .top-bar-animate {
    width: 100%;
    height: 0;
  }
  .top-bar-open {
    height: 40px;
    animation: headerOpen 0.8s;
  }
  .top-bar-fold {
    height: 0px;
    animation: headerClose 0.8s;
  }

  @keyframes flipOpen {
    0% {
      transform: perspective(400px) rotateX(90deg);
    }

    40% {
      transform: perspective(400px) rotateX(-10deg);
    }

    70% {
      transform: perspective(400px) rotateX(10deg);
    }

    100% {
      transform: perspective(400px) rotateX(0);
    }
  }
  @keyframes flipClose {
    0% {
      transform: perspective(400px) rotateX(-90deg);
    }

    40% {
      transform: perspective(400px) rotateX(10deg);
    }

    70% {
      transform: perspective(400px) rotateX(-10deg);
    }

    100% {
      transform: perspective(400px) rotateX(0);
    }
  }
}
.el-drawer__wrapper {
  .avatar-drawer {
    overflow: visible;
    width: 3rem !important;
    border-radius: 40px 0 0 40px;

    .el-drawer__body {
      overflow: visible;
      position: relative;
      .close-box {
        display: inline-block;

        width: 0.6rem;
        height: 0.6rem;
        // border-radius: 40px;
        // background-color: #ffffff;
        position: absolute;
        left: -45px;
        top: 45%;
        margin-top: -0.14rem;
        cursor: pointer;
        .img-close {
          position: absolute;
          left: 6px;
          top: 15px;
          // width: 100%;
          // height: 100%;
        }
        &:hover {
          .img-close {
            filter: saturate(125%) brightness(108%);
          }
        }
      }
      .drawer-content {
        position: relative;
        height: 100%;
        border-radius: 40px 0 0 40px;

        .drawer-top {
          width: 100%;
          // height: 140px;
          // line-height: 64px;
          // padding: 42px 0px 42px 54px;

          height: 1.4rem;
          line-height: 0.64rem;
          padding: 0.42rem 0px 0.42rem 0.54rem;

          border-radius: 50px 0 0 0;
          border-radius: 40px 0 0 0px;

          .img-avatar {
            display: inline-block;
            vertical-align: middle;

            width: 0.64rem;
            height: 0.64rem;
          }

          .span-name {
            display: inline-block;
            vertical-align: middle;
            margin-left: 0.1rem;
          }
          &.drawer-top-login {
            background: #ade787;
            .span-name-login {
              display: inline-block;
              line-height: 0.01rem;
              margin-top: 0.06rem;

              .span-name {
                display: inline-block;
                vertical-align: middle;
                line-height: 0.24rem;
                color: #448816;
                // font-size: 18px;
                font-size: 0.18rem;
                font-weight: 600;
                text-align: left;
                // width: 126px;
                width: 1.26rem;
                font-family: PingFangSC-Semibold, PingFang SC !important;
              }
              .span-phone {
                display: inline-block;
                vertical-align: middle;
                line-height: 0.24rem;

                color: #448816;
                font-size: 0.15rem;
                text-align: left;
                margin-left: 0.1rem;
                font-family: PingFangSC-Semibold, PingFang SC !important;
              }
            }
          }
          &.drawer-top-not-login {
            background: #f0f0f0;
            .span-name {
              color: #737373;
              font-size: 0.16rem;
              font-weight: bold;
            }
          }
        }
        .drawer-list {
          padding: 0 0.1rem;
          margin-top: 0.16rem;
          position: relative;
          .drawer-item {
            // height: 0.6rem;
            height: 0.6rem;
            line-height: 0.35rem;
            cursor: pointer;
            padding: 0 0.1rem;
            .img-icon {
              width: 0.34rem;
              height: 0.26rem;
              display: inline-block;
              vertical-align: middle;
            }
            .span-title {
              display: inline-block;
              vertical-align: middle;
              width: 80%;
              height: 0.6rem;
              line-height: 0.6rem;
              font-size: 0.16rem;
              font-weight: bold;
              color: #448816;
              margin-left: 0.15rem;
              border-bottom: 0.01rem solid #f7eadb;
            }

            &:hover {
              .img-icon,
              .span-title {
                opacity: 0.8;
              }
            }
            &:not(:last-of-type) {
              // border-bottom: 1px solid #f7eadb;
            }
          }
        }
        .drawer-bottom {
          position: absolute;
          bottom: 0.42rem;
          left: 0.54rem;
          // bottom: 0.42rem;
          margin: 0 auto;

          width: 2rem !important;
          height: 0.46rem !important;
          line-height: 0.46rem !important;
          text-align: center;
          font-size: 0.16rem !important;
        }
        .login-btn {
          width: 200px;
          height: 46px;
          line-height: 46px;
          text-align: center;
          font-size: 16px;
          &:hover {
            background: #d0f1b0 !important;
          }
        }
      }
    }
  }
}
.img-menu1 {
  // margin-top: 100px;
  position: relative;
  top: 0.16rem;
  animation: none 0 ease 0 1 normal;
  animation-name: none;
  transform-origin: center bottom;
  animation-duration: 0s;
  animation-fill-mode: none;
}
.select-menu {
  img:hover {
  }
}
